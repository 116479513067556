<template>
    <div class="page-wrapper" id="scroll_top">

        <div v-if="pageIsLoaded">
            <section class="about_home about_historique" v-for="(item, i) in content.splits" :key="i" >
                <div class="wrap">
                    <div class="data">
                        <h1 class="title small" data-inview="fadeInUp" data-delay="200">
                            {{ item.surtitre }}
                        </h1>
                        <h3 class="title medium" data-inview="fadeInUp" data-delay="300">
                            <span v-html="item.titre"></span>
                        </h3>
                        <p class="regular-text" data-inview="fadeInUp" data-delay="400">
                            <span v-html="item.texte"></span>
                        </p>
                        <a
                            :href="item.boutonUrl[$route.meta.lang]"
                            class="cta"
                            data-inview="fadeInUp"
                            data-delay="500"
                            v-if="item.bouton.value"
                        >
                            <p class="regular-text">{{ item.bouton.customText }}</p>
                            <img src="@/assets/img/arrow-right-dark.svg" alt="arrow right" />
                        </a>
                    </div>
                </div>
                <div class="img">
                    <img :src="item.image.url" :alt="item.image.titre" />
                </div>
            </section>
        </div>

        <RelatedAbout v-if="pageIsLoaded" :route="$route.path" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'
// import Flickity from 'flickity'

import RelatedAbout from '@/components/RelatedAbout'

export default {
    data() {
        return {
            lightboxelement: null,
        }
    },

    components: {
        RelatedAbout,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.lightboxelement = GLightbox
                        GLightbox()

                        setTimeout(() => {
                            // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        historiqueGauche() {
            if (!this.globalsIsLoaded) {
                return []
            }

            // Only odd numbers
            return this.content.listeInformationsEtImage.filter((item, i) => {
                return i % 2 === 0
            })
        },
        historiqueDroite() {
            if (!this.globalsIsLoaded) {
                return []
            }

            // Only even numbers
            return this.content.listeInformationsEtImage.filter((item, i) => {
                return i % 2 === 1
            })
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped>
.for-desktop {
    display: flex;
    @media (max-width: 850px) {
        display: none;
    }
}
.for-mobile {
    display: none;
    @media (max-width: 850px) {
        display: block;
    }
}
</style>
